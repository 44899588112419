*, ::before, ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0
}

body {
  background: linear-gradient(#738394, #343e4a);
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  color: #fff3d5;
  height: 100vh;
}