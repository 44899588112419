.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #282f38;
  width: 100%;
  height: 3.125em;
  padding: 10px;
  border-top: 1px solid rgba(255, 243, 213, 0.7);
  margin-top: 30px;
  flex-shrink: 0;
}

.footer p {
  font-size: 0.8em;
  text-align: center;
}

.footer a {
  text-decoration: none;
  color: #d1c087;
}

.modal-info ul {
  margin: 20px 0 20px 40px;
}

.modal-info a {
  color: #7a692f;
}

.modal-info {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff3d5;
  width: 90%;
  height: auto;
  border-radius: 10px;
  padding: 20px;
  font-size: 0.9em;
  color: #282f38;
}

.modal-info p {
  margin-bottom: 10px;
}

.modal-info p:last-child {
  margin-bottom: 0;
}

.modal-info h2 {
  font-size: 1.3em;
}

.delete-data {
  background-color: #cc6060;
  border: 0;
  border-radius: 20px;
  padding: 10px;
  color: #fff3d5;
  margin: 10px 0;
}

.close-modal {
  position: absolute;
  right: 20px;
  background-color: #cc6060;
  border: 0;
  border-radius: 20px;
  padding: 10px;
  color: #fff3d5;
}

.data-clear {
  background-color: #8fdb9c;
  color: #282f38;
}

@media (min-width: 45em) {
  .modal-info {
      width: 65%;
  }

  .delete-data {
    cursor: pointer;
  }

  .close-modal {
    cursor: pointer;
  }
}

@media (min-width: 75em) {
  .modal-info {
      width: 50%;
  }
}